import React from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const AppHelmet = () => {
    const location = useLocation();
    const getManifestFile = () => {
        switch (location.pathname) {
            case '/cueworkz':
                return '/manifest-cueworkz.json';
            case '/climb':
                return '/manifest-cueclimb.json';
            default:
                return '/manifest.json';
        }
    };

    const getPageMeta = () => {
        switch (location.pathname) {
            case '/cueworkz':
                return {
                    title: 'CueWORKZ - Work With Us!',
                    description: 'CueWORKZ offers professional services to elevate your business.',
                };
            case '/climb':
                return {
                    title: 'Climb - Reach New Heights',
                    description: 'Climb provides innovative solutions to help your business grow.',
                };
            case '/products':
                return {
                    title: 'Our Products - Organic & Innovative',
                    description:
                        'Browse our wide range of organic products for a healthier lifestyle.',
                };
            default:
                return {
                    title: 'Cueverz - Leading Software Innovation',
                    description: 'Cueverz specializes in cutting-edge software development.',
                };
        }
    };

    const { title, description } = getPageMeta();

    return (
        <Helmet>
            <link rel='manifest' href={getManifestFile()} />
            <title>{title}</title>
            <meta name='description' content={description} />
            <meta name='robots' content='index,follow' />
            <meta name='viewport' content='width=device-width, initial-scale=1' />
            <script type='application/ld+json'>
                {`
                        {
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [
                            {
                                "@type": "ListItem",
                                "position": 1,
                                "name": "Home",
                                "item": "https://cueverz.com/"
                            },
                            {
                                "@type": "ListItem",
                                "position": 2,
                                "name": "CueWorkz",
                                "item": "https://cueverz.com/cueworkz"
                            },
                            {
                                "@type": "ListItem",
                                "position": 3,
                                "name": "CLIMB",
                                "item": "https://cueverz.com/climb"
                            },
                            {
                                "@type": "ListItem",
                                "position": 4,
                                "name": "About Us",
                                "item": "https://cueverz.com/about-us"
                            },
                            {
                                "@type": "ListItem",
                                "position": 5,
                                "name": "Portfolio",
                                "item": "https://cueverz.com/portfolio"
                            },
                            {
                                "@type": "ListItem",
                                "position": 6,
                                "name": "Contact Us",
                                "item": "https://cueverz.com/contact-us"
                            }
                            ]
                        }
                    `}
            </script>
        </Helmet>
    );
};

export default AppHelmet;
